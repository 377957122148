import { profileConstants } from '../_constants';
import { profileService } from '../_services/profile';
import { handleError } from './handler';

export function profileClear(){
  return dispatch => {
    dispatch({type: profileConstants.PROFILE_CLEAR});
  };
};

export function getProfile() {
  return dispatch => {
    dispatch(request());
    return profileService
      .getProfile()
      .then(profile => dispatch(success(profile)))
      .catch(handleError(dispatch, failure));
  };

  function request() {
    return {type: profileConstants.PROFILE_GET_REQUEST};
  }

  function success(profile) {
    return {
      type: profileConstants.PROFILE_GET_SUCCESS,
      profile: profile,
    };
  }

  function failure(error) {
    return {
      type: profileConstants.PROFILE_GET_FAILURE,
      error,
    };
  }
};

export function updateProfile(userId, profileData) {
  return dispatch => {
    dispatch(request());
    return profileService
      .updateProfile(userId, profileData)
      .then(profile => dispatch(success(profile)))
      .catch(handleError(dispatch, failure));
  };

  function request() {
    return {type: profileConstants.PROFILE_UPDATE_REQUEST};
  }

  function success(profile) {
    return {
      type: profileConstants.PROFILE_UPDATE_SUCCESS,
      ...profile,
    };
  }

  function failure(error) {
    return {
      type: profileConstants.PROFILE_UPDATE_FAILURE,
      error,
    };
  }
};

export function updateAvatar(userId, file) {
  return dispatch => {
    dispatch({type: profileConstants.PROFILE_AVATAR_REQUEST});
    return profileService
      .updateAvatar(userId, file)
      .then(profile => dispatch(success(profile)))
      .catch(handleError(dispatch, failure));
  }

  function success(profile) {
    return {
      type: profileConstants.PROFILE_AVATAR_SUCCESS,
      ...profile,
    };
  }

  function failure(error) {
    return {
      type: profileConstants.PROFILE_AVATAR_FAILURE,
      error,
    };
  }
};
