import { Component } from 'react'
import * as qs from 'query-string'
import { connect } from 'react-redux'
import { loginAs } from '../_actions';

class LoginAs extends Component {

  constructor(props){
    super(props)

    const parsedQs = qs.parse(this.props.location.search)
    const userId = parsedQs.user_id
    const origin = parsedQs.origin

    this.props.loginAs(userId, origin)
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const actionCreators = {
    loginAs: loginAs
};

export default connect(
  mapStateToProps,
  actionCreators,
)(LoginAs)
