import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import * as qs from 'query-string'
import { connect } from 'react-redux'

import { verifyEmail } from '../_actions';

function createPath(path, origin){
  if (origin){
    return path + '?origin=' + origin
  }

  return path
}


class VerifyEmail extends Component {

  constructor(props){
    super(props)

    const parsedQs = qs.parse(this.props.location.search)
    this.token = parsedQs.token
    this.origin = parsedQs.origin

    this.state = {
      verifying: true
    }
  }

  componentDidMount(){
    const self = this;

    const { user } = this.props

    if (user && !user.verified){
      this.props.verifyEmail(this.token, this.origin)
        .then(function(r){
          self.setState({ verifying: false })
        })
        .catch(function(){
          self.setState({ verifying: false })
        })
    } else {
      self.setState({ verifying: false })
    }
  }

  render() {
    if (this.state.verifying) {
      return null;
    }

    return <Redirect to={createPath('/', this.origin)} />
  }
}

const mapStateToProps = (state) => {
  return {
    loggingOut: state.auth.loggingOut,
    user: state.auth.user
  }
}

const actionCreators = {
    verifyEmail: verifyEmail
};

export default connect(
  mapStateToProps,
  actionCreators,
)(VerifyEmail)
