import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from '../_helpers';
import PrivateRoute from '../_components/PrivateRoute';
import LoginRegister from '../LoginRegister/LoginRegister';
import VerifyEmail from '../_components/VerifyEmail';
import LoginAs from '../_components/LoginAs';
import Logout from '../_components/Logout';
import RegisterPending from '../RegisterPending/RegisterPending';
import HomePage from '../HomePage/HomePage';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Security from '../Security/Security';
import ResetPassword from '../ForgotPassword/ResetPassword';

class App extends React.Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path="/" component={HomePage}/>
            <PrivateRoute exact path="/security" component={Security}/>
            <Route path="/login" component={LoginRegister}/>
            <Route path="/forgot-password" component={ForgotPassword}/>
            <Route path="/reset-password" component={ResetPassword}/>
            <Route path="/verify" component={VerifyEmail}/>
            <Route path="/logout" component={Logout}/>
            <PrivateRoute path="/pending" component={RegisterPending}/>
            <PrivateRoute path="/loginas" component={LoginAs}/>
            <Redirect from="*" to="/"/>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

