import { authConstants } from '../_constants';

export function handleError(dispatch, failureFunction) {
  return response => {
    if (response.status === 401) {
      return dispatch({ type: authConstants.LOGOUT_SUCCESS });
    }
    
    return response.text().then(text => {
      try {
        const errorMessage = text && JSON.parse(text);
        return dispatch(failureFunction(errorMessage));
      } catch (err) {
        return dispatch(failureFunction('Request failed'));
      }
    });
  };
}
