import React from "react";
import { connect } from "react-redux";
import * as qs from "query-string";
import Particles from "react-particles-js";
import FormFooter from "../_components/FormFooter";
import logo from "../_images/logo.svg";
import { userValidator } from "../_helpers";
import { forgotPassword, forgotPasswordClear } from "../_actions/auth";
import ParticlesConfig from "../particlesjs-config.json";
import "./ForgotPassword.css";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errors: {
        email: "",
      },
    };

    const parsedQs = qs.parse(this.props.location.search);
    this.origin = parsedQs.origin;

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.props.forgotPasswordClear();
  }

  validate = (field, value) => {
    const validateField = userValidator[field] || (() => "");
    const error = validateField(value, false);
    const errors = {
      ...this.state.errors,
      [field]: error,
    };

    this.setState({ errors });

    return !error;
  };

  validateAll = () => {
    let valid = true;
    for (var field of ["email"]) {
      if (!this.validate(field, this.state[field])) {
        valid = false;
      }
    }

    return valid;
  };

  handleChange = (event, checked) => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [field]: value,
    });

    this.validate(field, value);
  };

  onSubmit = () => {
    if (!this.validateAll() || this.props.auth.sendingForgotPassword) {
      return;
    }

    this.props.forgotPassword(this.state.email, this.origin);
  };

  onKeyPressHandler = (event) => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  render() {
    return (
      <div className="Login-bg">
        <div className="Bg-animation">
          <Particles params={ParticlesConfig} width="100%" height="100%" />
        </div>

        <div className="container">
          <div>
            <div className="row">
              <div className="col-md-12 Reset-wrapper-container">
                <div className="Reset-wrapper">
                  <div className="blue-slider">
                    <div className="svg-container">
                      <svg
                        className="svg-curve"
                        viewBox="0 0 200 400"
                        preserveAspectRatio="none"
                      >
                        <path
                          className="s-path"
                          fill="#2D57CD"
                          d="M0,0 100,0 Q-100,200 100,400 L0,400"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="blue">
                    <div>
                      <img src={logo} alt="CIAL-Logo" className="logo" />

                      <div className="Toggle-title text-center text">
                        <div>Forgot password?</div>
                      </div>
                    </div>
                  </div>

                  <div className="white">
                    <div className="Form-content">
                      <div className="text-center text Form-title">
                        <div>Reset your password</div>
                        <div>
                          You will receive a link to reset your password
                        </div>
                      </div>

                      {this.props.auth.forgotPasswordError && (
                        <div
                          className="alert-message alert-message-danger"
                          role="alert"
                        >
                          <i className="icon-ic_exclamation-triangle" />
                          {this.props.auth.forgotPasswordError}
                        </div>
                      )}
                      {this.props.auth.forgotPasswordSuccess && (
                        <div
                          className="alert-message alert-message-success"
                          role="alert"
                        >
                          <i className="icon-ic_check-double" />
                          {this.props.auth.forgotPasswordSuccess}
                        </div>
                      )}
                      <div>
                        <input
                          onKeyDown={this.onKeyPressHandler}
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="E-mail"
                          onChange={this.handleChange}
                        />
                        {this.state.errors.email && (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errors.email}
                          </div>
                        )}
                      </div>
                      <button
                        className={
                          "btn Btn-primary Btn-round full-width" +
                          (this.props.auth.sendingForgotPassword
                            ? " disabled"
                            : "")
                        }
                        onClick={this.onSubmit}
                      >
                        Reset Password
                      </button>

                      <FormFooter />

                      <div className="Link-btn">
                        <span> or </span>
                        <a href="/login">Log in</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const actionCreators = {
  forgotPassword: forgotPassword,
  forgotPasswordClear: forgotPasswordClear,
};

const ForgotPasswordConnected = connect(
  mapStateToProps,
  actionCreators
)(ForgotPassword);

export default ForgotPasswordConnected;
