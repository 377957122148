export const authConstants = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGIN_REGISTER_CLEAR: 'LOGIN_REGISTER_CLEAR',

    SESSION_REFRESH_REQUEST: 'SESSION_REFRESH_REQUEST',
    SESSION_REFRESH_SUCCESS: 'SESSION_REFRESH_SUCCESS',
    SESSION_REFRESH_FAILURE: 'SESSION_REFRESH_FAILURE',
    
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_CLEAR: 'FORGOT_PASSWORD_CLEAR',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_CLEAR: 'RESET_PASSWORD_CLEAR',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
    UPDATE_PASSWORD_CLEAR: 'UPDATE_PASSWORD_CLEAR',

    RESEND_VERIF_EMAIL_REQUEST: 'RESEND_VERIF_EMAIL_REQUEST',
    RESEND_VERIF_EMAIL_SUCCEESS: 'RESEND_VERIF_EMAIL_SUCCESS',
    RESEND_VERIF_EMAIL_FAILURE: 'RESEND_VERIF_EMAIL_FAILURE',
    RESEND_VERIF_EMAIL_CLEAR: 'RESEND_VERIF_EMAIL_CLEAR',

    LOGIN_AS_REQUEST: 'LOGIN_AS_REQUEST',
    LOGIN_AS_SUCCESS: 'LOGIN_AS_SUCCESS',
    LOGIN_AS_FAILURE: 'LOGIN_AS_FAILURE',

    SET_LANGUAGE: 'SET_LANGUAGE',
};

