const { getLanguage } = require('../_helpers/language');

export const authService = {
  login,
  register,
  logout,
  resendVerificationEmail,
  verifyEmail,
  forgotPassword,
  resetPassword,
  updatePassword,
  getSession,
  loginAs
};

const USERS_ROUTE = '/api/v1/users';
const SESSIONS_ROUTE = '/api/v1/sessions';

function contentTypeHeader() {
  return { 
    'Content-Type': 'application/json'
  }
}

function login(email, password) {
  let body = {
    email: email,
    password: password
  }

  const requestOptions = {
    method: 'POST',
    headers: contentTypeHeader(),
    body: JSON.stringify(body),
  };

  return fetch(SESSIONS_ROUTE, requestOptions).then(handleResponse);
}

// TODO: Make a distinction between logging out of one vs all sessions.
// TODO: Make logging out of the one session a default.
// TODO: Logging out of /all/ sessions should be a separate less visible button.
function logout() {
  const requestOptions = {
    method: 'DELETE',
  };

  return fetch(SESSIONS_ROUTE, requestOptions)
    .then(handleResponse);
}

function resendVerificationEmail(email, origin) {
  let body = {
    email: email
  }

  let url = `${USERS_ROUTE}/email`
  if (origin){
    url += '?origin=' + origin
  }

  const requestOptions = {
    method: 'DELETE',
    headers: contentTypeHeader(),
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions)
    .then(handleResponse);
}

function forgotPassword(email, origin) {
  const requestOptions = {
    method: 'DELETE',
    headers: contentTypeHeader(),
    body: JSON.stringify({email}),
  };

  let url = `${USERS_ROUTE}/password`;
  if (origin){
    url += '?origin=' + origin;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function resetPassword(token, password) {
  let body = {
    token: token,
    password: password
  }

  const requestOptions = {
    method: 'POST',
    headers: contentTypeHeader(),
    body: JSON.stringify(body),
  };

  return fetch(`${USERS_ROUTE}/password`, requestOptions)
    .then(handleResponse);
}

function updatePassword(oldPassword, newPassword) {
  let body = {
    password_old: oldPassword,
    password_new: newPassword
  }

  const requestOptions = {
    method: 'PUT',
    headers: contentTypeHeader(),
    body: JSON.stringify(body),
  };

  return fetch(`${USERS_ROUTE}/password`, requestOptions)
    .then(handleResponse)
}

function register(full_name, email, password, origin, language) {
  let body = {
    name: full_name,
    email: email,
    password: password,
    language: language,
  }

  const requestOptions = {
    method: 'POST',
    headers: contentTypeHeader(),
    body: JSON.stringify(body),
  };

  let url = USERS_ROUTE
  if (origin){
    url += '?origin=' + origin
  }

  return fetch(url, requestOptions)
    .then(handleResponse);
}

function verifyEmail(token) {
  const url = `${USERS_ROUTE}/email?token=${token}`;

  const requestOptions = {
    method: 'POST',
  };

  return fetch(url, requestOptions)
    .then(handleResponse);
}

function getSession(){
  const url = `${SESSIONS_ROUTE}/me`;

  const requestOptions = {
    method: 'GET',
  };

  return fetch(url, requestOptions)
    .then(handleResponse);
}

function loginAs(userId) {

  const requestOptions = {
    method: 'POST'
  };

  return fetch(`${SESSIONS_ROUTE}/as?user_id=` + userId, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  if (!response.ok){
    return Promise.reject(response)
  }

  return response.text().then(text => {
    const data = text && JSON.parse(text);

    return data;
  });
}
