import React, { Component } from "react";
import { connect } from "react-redux";
import { userValidator } from "../_helpers";
import FormFooter from "../_components/FormFooter";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      errors: {
        email: "",
        password: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitLogin = props.onSubmit;
  }

  validate = (field, value) => {
    const validateField = userValidator[field] || (() => "");
    const error = validateField(value, false);
    const errors = {
      ...this.state.errors,
      [field]: error,
    };

    this.setState({ errors });
    return !error;
  };

  validateAll = () => {
    let valid = true;
    for (var field of ["email", "password"]) {
      if (!this.validate(field, this.state[field])) {
        valid = false;
      }
    }

    return valid;
  };

  handleChange = (event, checked) => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [field]: value,
    });

    this.validate(field, value);
  };

  onSubmit = () => {
    if (!this.validateAll() || this.props.auth.loggingIn) {
      return;
    }
    this.submitLogin(this.state.email, this.state.password);
  };

  render() {
    return (
      <div className="Form-content">
        <div className="text-center text Form-title">
          <div>Member Login</div>
        </div>
        {this.props.auth.loginError && (
          <div className="alert-message alert-message-danger" role="alert">
            <i className="icon-ic_exclamation-triangle" />
            {this.props.auth.loginError}
          </div>
        )}
        <div>
          <input
            onKeyDown={this.onKeyPressHandler}
            type="text"
            className="form-control"
            name="email"
            placeholder="E-mail"
            onChange={this.handleChange}
          />

          <input
            onKeyDown={this.onKeyPressHandler}
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            onChange={this.handleChange}
          />

          <a
            href={"/forgot-password" + this.props.location.search}
            className="Forgot-password"
          >
            Forgot password?
          </a>
        </div>
        <button
          type="submit"
          className={
            "btn Btn-primary Btn-round full-width " +
            (this.props.auth.loggingIn === true ? " disabled" : "")
          }
          onClick={this.onSubmit}
        >
          Login
        </button>

        <FormFooter />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const LoginFormConnected = connect(mapStateToProps, null)(LoginForm);

export default LoginFormConnected;
