import React from 'react';
import { connect } from 'react-redux';
import { userValidator } from '../_helpers';
import { updatePassword, updatePasswordClear } from '../_actions';
import AppWrapper from '../_components/AppWrapper/AppWrapper.js'
import './Security.css';

class Security extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: null,
      newPassword: null,
      newPasswordConfirm: null,
      errors: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.updatePasswordClear()
  }

  validatePasswordConfirmed = (value) => {
    value = value || this.state.newPasswordConfirm
    let errors = {
        ...this.state.errors,
        newPasswordConfirm: ''
    }
    if (this.state.newPassword !== value) {
      errors.newPasswordConfirm =  'Passwords do not match'
    }

    this.setState({ errors })

    return !errors.newPasswordConfirm
  }

  validatePassword = (field, value) => {
    const validateField = userValidator['password'] || (() => '')
    const error = validateField(value, false)
    const errors = {
      ...this.state.errors,
      [field]: error
    }

    this.setState({ errors }, this.validatePasswordConfirmed)
    return !error
  }

  validate = (field, value) => {
    if (field === 'newPasswordConfirm'){
      return this.validatePasswordConfirmed(value)
    }

    if (field === 'newPassword'){
      return this.validatePassword(field, value)
    }

    return this.validatePassword(field, value)
  }

  validateAll = () => {
    let valid = true
    for (var field of ['oldPassword', 'newPassword', 'newPasswordConfirm']){
      if (!this.validate(field, this.state[field])){
        valid = false
      }
    }

    return valid
  }

  handleChange = (event) => {
    const field = event.target.name
    const value = event.target.value
    this.setState({ ...this.state, [field]: value })
    this.validate(field, value)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (!this.validateAll() || this.props.auth.updatingPassword) {
        return
    }

    this.props.updatePassword(this.state.oldPassword, this.state.newPassword)
  }

  render(){
    return (


        <AppWrapper>
            <div className="Account">

                  <div className="Header-title">
                      Security
                  </div>

                  <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                          <div className="Card">
                              <div className="Card-body">
                                  <div className="Card-title">
                                      Change password
                                  </div>




                                  <div className="form-group row">
                                      <label className="col-sm-3 col-form-label">
                                          Old Password

                                      </label>

                                      <div className="col-sm-9">
                                          <input type='password' className='form-control' placeholder='Enter password' name='oldPassword' onChange={this.handleChange}/>
                                          {this.state.errors.oldPassword && (
                                              <div className="alert alert-danger" role="alert">
                                                {this.state.errors.oldPassword}
                                              </div>
                                           )}
                                      </div>

                                  </div>

                                  <div className="form-group row">
                                      <label className="col-sm-3 col-form-label">
                                          New Password

                                      </label>

                                      <div className="col-sm-9">
                                          <input type='password' className='form-control' placeholder='Enter password' name='newPassword' onChange={this.handleChange}/>
                                          {this.state.errors.newPassword && (
                                              <div className="alert alert-danger" role="alert">
                                                {this.state.errors.newPassword}
                                              </div>
                                           )}
                                      </div>

                                  </div>



                                  <div className="form-group row">
                                      <label className="col-sm-3 col-form-label">
                                          Confirm New Password

                                      </label>

                                      <div className="col-sm-9">
                                          <input type='password' className='form-control' placeholder='Enter password' name='newPasswordConfirm' onChange={this.handleChange}/>
                                          {this.state.errors.newPasswordConfirm && (
                                              <div className="alert alert-danger" role="alert">
                                                {this.state.errors.newPasswordConfirm}
                                              </div>
                                           )}
                                      </div>

                                  </div>

                                <div className="row Buttons-inline">
                                    <div className="col-sm-9 ml-auto">
                                        <button className={'btn Btn-primary' + (this.props.auth.updatingPassword === true ? ' disabled': '')} onClick={this.handleSubmit}>Set new password</button>
                                    </div>
                                </div>
                                {this.props.auth.updatePasswordError && (
                                    <div className="alert-message alert-message-danger" role="alert">
                                        <i className="icon-ic_exclamation-triangle"/>
                                        {this.props.auth.updatePasswordError}
                                    </div>
                                )}
                                {this.props.auth.updatePasswordSuccess && (
                                    <div className="alert-message alert-message-success" role="alert">
                                        <i className="icon-ic_exclamation-triangle"/>
                                        {this.props.auth.updatePasswordSuccess}
                                    </div>
                                )}
                              </div>
                          </div>
                      </div>
                  </div>
            </div>
        </AppWrapper>
    );
  }
}
  
const actionCreators = {
  updatePassword: updatePassword,
  updatePasswordClear: updatePasswordClear,
};

const mapStateToProps = (state) => {
  return { auth: state.auth }
}

export default connect(
  mapStateToProps,
  actionCreators,
)(Security)
