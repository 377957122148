import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login, register, loginRegisterClear } from "../_actions";
import logo from "../_images/logo.svg";
import btnArrow from "../_images/btn-arrow.svg";
import * as qs from "query-string";

import "./wizard-animation.css";
import "./LoginRegister.css";

import Particles from "react-particles-js";
import ParticlesConfig from "../particlesjs-config.json";

import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import LoginRegisterMobile from "./LoginRegisterMobile";

class LoginRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerOpen: false
    };

    const parsedQs = qs.parse(this.props.location.search);
    this.origin = parsedQs.origin;

    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.onSubmitRegister = this.onSubmitRegister.bind(this);

    this.props.loginRegisterClear();
  }

  onSubmitLogin = (email, password) => {
    this.props.login(email, password, this.origin);
  };

  onSubmitRegister = (full_name, email, password, language) => {
    this.props.register(full_name, email, password, this.origin, language);
  };

  onSwitchHandler = () =>
    this.setState({
      registerOpen: !this.state.registerOpen
    });

  render() {
    const { user } = this.props;
    if (user) {
      return (
        <Redirect
          to={{
            pathname: "/",
            search: this.props.location.search
          }}
        />
      );
    }

    return (
      <div className="Login-bg">
        <div className="Bg-animation">
          <Particles params={ParticlesConfig} width="100%" height="100%" />
        </div>
        <div className="d-block d-md-none">
          <LoginRegisterMobile
            location={this.props.location}
            onSubmitRegister={this.onSubmitRegister}
            onSubmitLogin={this.onSubmitLogin}
            isRegisterOpen={this.state.registerOpen}
            onSwitchHandler={this.onSwitchHandler}
          />
        </div>
        <div className="container Login-container d-none d-md-block">
          <div>
            <div className="row">
              <div className="col-md-12 Login-wrapper-container">
                <div
                  className={`Login-wrapper ${
                    this.state.registerOpen ? "switch" : ""
                  }`}
                >
                  <div className="blue-slider">
                    <div className="svg-container">
                      <svg
                        className="svg-curve"
                        viewBox="0 0 200 400"
                        preserveAspectRatio="none"
                      >
                        <path
                          className="s-path"
                          fill="#2D57CD"
                          d={
                            this.state.registerOpen
                              ? "M0,0 100,0 Q300,200 100,400 L0,400"
                              : "M0,0 100,0 Q-100,200 100,400 L0,400"
                          }
                        />
                      </svg>
                      <div className="Btn-arrow" onClick={this.onSwitchHandler}>
                        <img
                          className="Btn-arrow-img"
                          src={btnArrow}
                          alt="Toggle between login and register"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="blue">
                    <img src={logo} alt="CIAL-Logo" className="logo" />
                    <div
                      className="Toggle-title text-center text"
                      onClick={this.onSwitchHandler}
                    >
                      <div>Don't have an account?</div>
                      <div>Sign up</div>
                    </div>

                    <RegisterForm
                      location={this.props.location}
                      onSubmit={this.onSubmitRegister}
                    />
                  </div>

                  <div className="white">
                    <div
                      className="Toggle-title text-center text"
                      onClick={this.onSwitchHandler}
                    >
                      <div>Already have an account?</div>
                      <div>Log in</div>
                    </div>

                    <LoginForm
                      location={this.props.location}
                      onSubmit={this.onSubmitLogin}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

const actionCreators = {
  login: login,
  register: register,
  loginRegisterClear: loginRegisterClear
};

const LoginRegisterConnected = connect(
  mapStateToProps,
  actionCreators
)(LoginRegister);

export default LoginRegisterConnected;
