/*
 * Language descriptions in their own language.
 *
 * The first language is also the default one.
 */
export const LANGUAGES = {
  en: "English",
  es: "Español",
  "pt-BR": "Português"
};

/*
 * Get a language's description in that very language.
 */
export function getLanguageName(lang) {
  return LANGUAGES[lang] || lang;
}

/*
 * Get the first browser's preferred language that is also a supported one,
 * defaulting to 'en-US' if none match.
 */
export function getLanguage() {
  let languages = [];

  // Modern browsers provide the entire list of preferred languages.
  if (navigator.languages) {
    languages = languages.concat(navigator.languages);
  }

  // Older ones only provide only the UI's language, which is an ok proxy.
  if (navigator.language) {
    languages.push(navigator.language);
  }

  for (let lang of languages) {
    if (LANGUAGES[lang]) {
      return lang;
    }
  }

  // Fallback.
  for (let lang in LANGUAGES) {
    return lang;
  }
}
