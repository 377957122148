import React, { Component } from 'react';
import Sidebar from "../Sidebar/Sidebar.js";
import Header from "../Header/Header";

class AppWrapper extends Component {
  render() {
    return (
      <div className="AppWrapper d-flex flex-row">
        <Sidebar linkSelected={this.props.linkSelected}/>
        <Header/>
        <div className="container-fluid">
          <div className="AppWrapper-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default AppWrapper;
