import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist'
import rootReducer from './_reducers';

var store = null;

export function getToken() {
  if(!store || !store.getState()){
    return null;
  }
  return store.getState().auth.token;
}

export function configureStore(initialState = {}, history) {
  const middleware = [];

  middleware.push(routerMiddleware(history));
  middleware.push(thunkMiddleware);

  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger)
  }

  // TODO is this needed
  //const reducers = connectRouter(history)(rootReducer);

  store = createStore(
    rootReducer(history),
    initialState,
    applyMiddleware(...middleware)
  );

  return { store, persistor: persistStore(store) }
}
