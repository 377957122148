import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { default as phoneLocalEn } from "react-phone-number-input/locale/en";
import { default as phoneLocalEs } from "react-phone-number-input/locale/es";
import { default as phoneLocalPt } from "react-phone-number-input/locale/pt";
import { Form } from "react-bootstrap";
import { BounceLoader } from "react-spinners";
import { profileValidator } from "../_helpers";
import { LANGUAGES, getLanguageName } from "../_helpers/language";
import { getProfile, updateProfile, updateAvatar } from "../_actions";
import AppWrapper from "../_components/AppWrapper/AppWrapper.js";
import "./HomePage.css";

const languageMapping = {
  en: phoneLocalEn,
  es: phoneLocalEs,
  'pt-BR': phoneLocalPt
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      avatarLoading: false,
      errors: { name: "", phone: "" }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAvatarClick = this.handleAvatarClick.bind(this);
    this.handleAvatarUpload = this.handleAvatarUpload.bind(this);
  }

  componentDidMount() {
    this.props.getProfile().then(r => {
      this.setState({ profile: { ...this.props.profile.profile } });
    });
  }

  componentDidUpdate() {
    if (this.state.avatarLoading && !this.props.profile.updatingProfile) {
      this.setState({
        avatarLoading: false
      });
    }
  }

  validate = (field, value) => {
    const validateField = profileValidator[field] || (() => "");
    const error = validateField(value, false);
    const errors = {
      ...this.state.errors,
      [field]: error
    };

    this.setState({ errors });
    return !error;
  };

  validateAll = () => {
    let valid = true;
    for (var field of ["name", "phone"]) {
      if (!this.validate(field, this.state["profile"][field])) {
        valid = false;
        break;
      }
    }

    return valid;
  };

  handleChange = (field, value) => {
    const profile = { ...this.state.profile };
    profile[field] = value;
    this.setState({ profile });
    this.validate(field, value);
  };

  handleChangeDefault = event => {
    const field = event.target.name;
    const value = event.target.value;
    this.handleChange(field, value);
  };

  handlePhoneChange = value => {
    this.handleChange("phone", value);
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validateAll() || this.props.profile.updateProfile) {
      return;
    }

    this.props.updateProfile(
      this.state.profile.id,
      _.pick(this.state.profile, ["name", "phone", "language"])
    );
  };

  /* When clicking on avatar, emulate a click on the hidden file input instead. */
  handleAvatarClick = event => {
    this.refs.avatarBrowse.click();
  };

  /* Set a chosen avatar as soon as it is selected. */
  handleAvatarUpload = event => {
    event.preventDefault();

    const file = this.refs.avatarBrowse.files[0];
    if (file) {
      this.setState({
        avatarLoading: true
      });
      this.props.updateAvatar(this.state.profile.id, file);
    }
  };

  render() {
    if (!this.state.profile) {
      return null;
    }

    return (
      <AppWrapper>
        <div className="Account">
          <div className="Header-title">Personal Info</div>

          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="Card">
                <div className="Card-body">
                  <div className="Card-title">Profile</div>

                  <div className="form-group row Profile-picture">
                    <label className="col-sm-3 col-form-label">
                      Profile picture
                    </label>
                    <div className="col-sm-9">
                      <div
                        className={`Account-avatar ${
                          this.state.avatarLoading
                            ? "Accont-avatar--loading"
                            : ""
                        }`}
                      >
                        <img
                          alt="Click to change your profile picture"
                          src={this.props.profile.profile.avatarUrl}
                          onClick={this.handleAvatarClick}
                        />
                        <div className="Avatar-loader">
                          <BounceLoader
                            size={40}
                            color="#3fb2ff"
                            loading={this.state.avatarLoading}
                          />
                        </div>
                        <form className="Avatar-upload-form">
                          <input
                            ref="avatarBrowse"
                            type="file"
                            onChange={this.handleAvatarUpload}
                          />
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Full name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Full name"
                        value={this.state.profile.name}
                        onChange={this.handleChangeDefault}
                      />
                    </div>
                  </div>

                  {this.state.errors.name && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.errors.name}
                    </div>
                  )}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Work email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Work Email"
                        value={this.state.profile.email}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Phone</label>
                    <div className="col-sm-9">
                      <PhoneInput
                        className="form-control"
                        placeholder="example: +58 98375545"
                        value={this.state.profile.phone}
                        onChange={this.handlePhoneChange}
                        labels={languageMapping[this.state.profile.language || 'en']}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Language</label>
                    <div className="col-sm-9">
                      <Form.Control
                        as="select"
                        name="language"
                        defaultValue={this.state.profile.language}
                        onChange={this.handleChangeDefault}
                      >
                        {Object.keys(LANGUAGES).map(lang => {
                          return (
                            <option value={lang} key={lang}>
                              {getLanguageName(lang)}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-9 ml-auto">
                      <button
                        onClick={this.handleSubmit}
                        className={
                          "btn Btn-primary" +
                          (this.props.profile.updatingProfile
                            ? " disabled"
                            : "")
                        }
                      >
                        Update changes
                      </button>
                    </div>
                  </div>

                  {this.props.profile.updateProfileError && (
                    <div
                      className="alert-message alert-message-danger"
                      role="alert"
                    >
                      <i className="icon-ic_exclamation-triangle" />
                      {this.props.profile.updateProfileError}
                    </div>
                  )}

                  {this.props.profile.updateProfileSuccess && (
                    <div
                      className="alert-message alert-message-success"
                      role="alert"
                    >
                      <i className="icon-ic_exclamation-triangle" />
                      {this.props.profile.updateProfileSuccess}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    );
  }
}

const actionCreators = {
  getProfile: getProfile,
  updateProfile: updateProfile,
  updateAvatar: updateAvatar
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    profile: state.profile
  };
};

export default connect(mapStateToProps, actionCreators)(HomePage);
