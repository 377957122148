import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { profileReducer } from './profile';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// TODO add persistReducer for auth
const rootReducer = history => combineReducers({
  //auth: authReducer,
  auth: persistReducer({ key: 'auth', storage }, authReducer),
  profile: profileReducer,
  router: connectRouter(history)
});

export default rootReducer;
