import { Component } from 'react'
import * as qs from 'query-string'
import { connect } from 'react-redux'
import { history } from '../_helpers';
import { logout } from '../_actions';

class Logout extends Component {

  constructor(props){
    super(props)

    const parsedQs = qs.parse(this.props.location.search)
    this.origin = parsedQs.origin

    const { user } = this.props

    if (user){
      this.props.logout()
    }
  }

  render() {
    const { user } = this.props
    if (!user){
      if (this.origin){
        return window.location.assign(this.origin);
      } else {
        history.push('/');
      }
    } 

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const actionCreators = {
    logout: logout
};

export default connect(
  mapStateToProps,
  actionCreators,
)(Logout)
