import React from "react";
import { connect } from "react-redux";
import * as qs from "query-string";
import Particles from "react-particles-js";
import FormFooter from "../_components/FormFooter";
import logo from "../_images/logo.svg";
import { userValidator } from "../_helpers";
import { resetPassword, resetPasswordClear } from "../_actions";
import ParticlesConfig from "../particlesjs-config.json";
import "./ForgotPassword.css";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    const parsedQs = qs.parse(this.props.location.search);
    this.token = parsedQs.token;
    this.origin = parsedQs.origin;

    this.state = {
      password: "",
      passwordRepeat: "",
      errors: {
        password: "",
        passwordRepeat: ""
      }
    };

    this.props.resetPasswordClear();
  }

  validate = (field, value) => {
    let error = "";

    if (field === "passwordRepeat") {
      const validateField = userValidator[field] || (() => "");
      error = validateField(value, false);
      if (!error && this.state.password !== value) {
        error = "Passwords do not match";
      }
    } else {
      const validateField = userValidator[field] || (() => "");
      error = validateField(value, false);
    }

    const errors = {
      ...this.state.errors,
      [field]: error
    };

    this.setState({ errors });

    return !error;
  };

  validateAll = () => {
    let valid = true;
    for (var field of ["password", "passwordRepeat"]) {
      if (!this.validate(field, this.state[field])) {
        valid = false;
      }
    }

    return valid;
  };

  handleChange = (event, checked) => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [field]: value
    });

    this.validate(field, value);
  };

  onSubmit = () => {
    if (!this.validateAll() || this.props.auth.changingPassword) {
      return;
    }

    this.props.resetPassword(this.token, this.state.password, this.origin);
  };

  render() {
    return (
      <div className="Login-bg">
        <div className="Bg-animation">
          <Particles params={ParticlesConfig} width="100%" height="100%" />
        </div>

        <div className="container">
          <div>
            <div className="row">
              <div className="col-md-12 Reset-wrapper-container">
                <div className="Reset-wrapper">
                  <div className="blue-slider">
                    <div className="svg-container">
                      <svg
                        className="svg-curve"
                        viewBox="0 0 200 400"
                        preserveAspectRatio="none"
                      >
                        <path
                          className="s-path"
                          fill="#2D57CD"
                          d="M0,0 100,0 Q-100,200 100,400 L0,400"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="blue">
                    <div>
                      <img src={logo} alt="CIAL-Logo" className="logo" />

                      <div className="Toggle-title text-center text">
                        <div>Change password</div>
                      </div>
                    </div>
                  </div>

                  <div className="white">
                    <div className="Form-content">
                      <div className="text-center text Form-title">
                        <div>Change password</div>
                        <div></div>
                      </div>

                      {this.props.auth.resetPasswordError && (
                        <div
                          className="alert-message alert-message-danger"
                          role="alert"
                        >
                          <i className="icon-ic_exclamation-triangle" />
                          {this.props.auth.resetPasswordError}
                        </div>
                      )}

                      <div>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="New password"
                          onChange={this.handleChange}
                        />
                        {this.state.errors.password && (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errors.password}
                          </div>
                        )}
                        <input
                          type="password"
                          className="form-control"
                          name="passwordRepeat"
                          placeholder="Repeat new password"
                          onChange={this.handleChange}
                        />
                        {this.state.errors.passwordRepeat && (
                          <div className="alert alert-danger" role="alert">
                            {this.state.errors.passwordRepeat}
                          </div>
                        )}
                      </div>
                      <button
                        className={
                          "btn Btn-primary Btn-round full-width" +
                          (this.props.auth.changingPassword ? " disabled" : "")
                        }
                        onClick={this.onSubmit}
                      >
                        Change Password
                      </button>

                      <FormFooter />

                      <div className="Link-btn">
                        <span> or </span>
                        <a href={"/login" + this.props.location.search}>
                          Log in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const actionCreators = {
  resetPassword: resetPassword,
  resetPasswordClear: resetPasswordClear
};

export default connect(mapStateToProps, actionCreators)(ResetPassword);
