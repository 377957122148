import React, { Component } from 'react'
import { connect } from 'react-redux';
import { logout, getProfile } from '../../_actions';
import { Dropdown } from "react-bootstrap/";
import './Header.css';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {profile: null};
  }

  componentDidMount(){
    this
      .props
      .getProfile()
      .then(r => this.setState({profile: {...this.props.profile}}));
  }

  render() {
    if (!this.state.profile) {
      return null;
    }

    return (
      <div className="Header">
        <div className="float-right Header-link Profile">

          <Dropdown alignRight>
            <Dropdown.Toggle variant="neutral" className="d-flex align-items-center">
              <div className="Account-avatar">
                <img
                  alt="User"
                  src={this.props.profile.profile.avatarUrl}/>
              </div>
              <span>{this.state.profile.profile.name}</span>
              <i className="icon-ic_arrow"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/logout">Log out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  logout: logout,
  getProfile: getProfile,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    profile: state.profile,
  };
}

export default connect(
  mapStateToProps,
  actionCreators,
)(Header);
