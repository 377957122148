import { authConstants } from '../_constants';
import { authService } from '../_services/auth';
import { handleError } from './handler';
import { history } from '../_helpers';


function createPath(path, origin){
      if (origin){
        return path + '?origin=' + origin
      }

      return path
    }

export function loginRegisterClear(){
    return dispatch => {
        dispatch({ type: authConstants.LOGIN_REGISTER_CLEAR });
    };
}

export function resendVerifyClear(){
    return dispatch => {
        dispatch({ type: authConstants.RESEND_VERIF_EMAIL_CLEAR });
    };
}

export function forgotPasswordClear(){
    return dispatch => {
        dispatch({ type: authConstants.FORGOT_PASSWORD_CLEAR });
    };
}

export function resetPasswordClear(){
    return dispatch => {
        dispatch({ type: authConstants.RESET_PASSWORD_CLEAR });
    };
}

export function updatePasswordClear(){
    return dispatch => {
        dispatch({ type: authConstants.UPDATE_PASSWORD_CLEAR });
    };
}

export function login(email, password, origin) {
    return dispatch => {
        dispatch(request({ email }));

        authService.login(email, password)
            .then(result => {
              dispatch(success(result));
              const path = createPath('/', origin)
              history.push(path);
            })
            .catch(response => {
              return response.text().then(text => {
                try {
                  const errorMessage = text && JSON.parse(text);
                  dispatch(failure(errorMessage));
                } catch (err) {
                  dispatch(failure('Login failed'));
                }
              });
        });
    };

  function request(email) {
    return { type: authConstants.LOGIN_REQUEST, email }
  }
  function success(result) {
    return { type: authConstants.LOGIN_SUCCESS, ...result }
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error }
  }
}

export function logout() {
    return dispatch => {
        dispatch(request());
        authService.logout()
            .then(
                user => { 
                    dispatch(success());
                    history.push('/login');
            })
            .catch(handleError(dispatch, failure))
    };

  function request() {
    return { type: authConstants.LOGOUT_REQUEST }
  }
  function success() {
    return { type: authConstants.LOGOUT_SUCCESS }
  }
  function failure(error) {
    return { type: authConstants.LOGOUT_FAILURE, error }
  }
}

export function verifyEmail(token, origin) {
    return dispatch => {
        dispatch(request({ token }));

        return authService.verifyEmail(token)
            .then(result => {
              dispatch(success(result));
              //const path = createPath('/', origin)
              //history.push(path);
            })
            .catch(handleError(dispatch, failure))
    };

  function request(token) {
    return { type: authConstants.VERIFY_EMAIL_REQUEST, token }
  }
  function success(result) {
    return { type: authConstants.VERIFY_EMAIL_SUCCESS, ...result }
  }
  function failure(error) {
    return { type: authConstants.VERIFY_EMAIL_FAILURE, error }
  }
}

export function refreshSession() {
    return dispatch => {
        dispatch(request({}));

        return authService.getSession()
            .then(result => {
              dispatch(success(result));
            })
            .catch(handleError(dispatch, failure))
    };

  function request(token) {
    return { type: authConstants.SESSION_REFRESH_REQUEST }
  }
  function success(result) {
    return { type: authConstants.SESSION_REFRESH_SUCCESS, ...result  }
  }
  function failure(error) {
    return { type: authConstants.SESSION_REFRESH_FAILURE, error }
  }
}

export function loginAs(userId, origin) {
    return dispatch => {
        dispatch(request({ userId }));

        authService.loginAs(userId)
            .then(result => {
              dispatch(success(result));
              const path = createPath('/', origin)
              history.push(path);
            })
            .catch(handleError(dispatch, failure))
    };

  function request(userId) {
    return { type: authConstants.LOGIN_AS_REQUEST, userId }
  }
  function success(result) {
    return { type: authConstants.LOGIN_AS_SUCCESS, ...result }
  }
  function failure(error) {
    return { type: authConstants.LOGIN_AS_FAILURE, error }
  }
}


export function resendVerificationEmail(email, origin) {
    return dispatch => {
        dispatch(request());
        authService.resendVerificationEmail(email, origin)
            .then(_ => { 
              dispatch(success());
            })
            .catch(handleError(dispatch, failure))
    };

  function request() {
    return { type: authConstants.RESEND_VERIF_EMAIL_REQUEST }
  }
  function success() {
    return { type: authConstants.RESEND_VERIF_EMAIL_SUCCEESS }
  }
  function failure(error) {
    return { type: authConstants.RESEND_VERIF_EMAIL_FAILURE, error }
  }
}

export function forgotPassword(email, origin) {
    return dispatch => {
        dispatch(request());
        authService.forgotPassword(email, origin)
            .then(_ => { 
              dispatch(success());
            })
            .catch(handleError(dispatch, failure))
    };

  function request() {
    return { type: authConstants.FORGOT_PASSWORD_REQUEST }
  }
  function success() {
    return { type: authConstants.FORGOT_PASSWORD_SUCCESS }
  }
  function failure(error) {
    return { type: authConstants.FORGOT_PASSWORD_FAILURE, error }
  }
}

export function resetPassword(token, password, origin) {
    return dispatch => {
        dispatch(request());
        authService.resetPassword(token, password)
            .then(result => {
              dispatch(success(result));
              const path = createPath('/', origin)
              history.push(path);
            })
            .catch(handleError(dispatch, failure))
    };

  function request() {
    return { type: authConstants.RESET_PASSWORD_REQUEST }
  }
  function success(result) {
    return { type: authConstants.RESET_PASSWORD_SUCCESS, ...result }
  }
  function failure(error) {
    return { type: authConstants.RESET_PASSWORD_FAILURE, error }
  }
}

export function updatePassword(oldPassword, newPassword) {
    return dispatch => {
        dispatch(request());
        authService.updatePassword(oldPassword, newPassword)
            .then(result => {
              dispatch(success(result));
            })
            .catch(handleError(dispatch, failure))
    };

  function request() {
    return { type: authConstants.UPDATE_PASSWORD_REQUEST }
  }
  function success(result) {
    return { type: authConstants.UPDATE_PASSWORD_SUCCESS, ...result }
  }
  function failure(error) {
    return { type: authConstants.UPDATE_PASSWORD_FAILURE, error }
  }
}

export function register(full_name, email, password, origin, language) {
    return dispatch => {
        dispatch(request());
        authService.register(full_name, email, password, origin, language)
            .then(result => {
              dispatch(success(result));
              const path = createPath('/pending', origin)
              history.push(path);
            })
            .catch(handleError(dispatch, failure))
    };

  function request() {
    return { type: authConstants.REGISTER_REQUEST }
  }
  function success(result) {
    return { type: authConstants.REGISTER_SUCCESS, ...result }
  }
  function failure(error) {
    return { type: authConstants.REGISTER_FAILURE, error }
  }
}

export function setLanguage(language) {
    return dispatch => {
        dispatch({type: authConstants.SET_LANGUAGE, language: language});
    };
}
