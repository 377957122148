var emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const userValidator = {
  full_name: function(value, edit = false){
    if (!value){
      return 'Full name must be provided';
    }

    return null;
  },

  email: function(value, edit = false){
    if (!value){
      return 'Email must be provided';
    }

    if(!emailPattern.test(String(value).toLowerCase())){
      return 'Email is not valid'
    }

    return null;
  },

  password: function(value){
      if(!value){
        return 'Password must be provided'
      }

      if(value.length < 7){
        return 'Password must be at least 7 characters long' 
      }
  }
}
