import React, { Component } from "react";
import { connect } from "react-redux";
import * as qs from "query-string";
import Particles from "react-particles-js";
import FormFooter from "../_components/FormFooter";
import logo from "../_images/logo.svg";
import "./RegisterPending.css";
import {
  logout,
  resendVerificationEmail,
  resendVerifyClear
} from "../_actions";
import ParticlesConfig from "../particlesjs-config.json";

class RegisterPending extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClick = this.onClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const parsedQs = qs.parse(this.props.location.search);
    this.origin = parsedQs.origin;

    this.props.resendVerifyClear();
  }

  onClick = e => {
    e.preventDefault();
    this.props.logout();
  };

  onSubmit = () => {
    if (this.props.auth.resendingVerify) {
      return;
    }
    this.props.resendVerificationEmail(this.props.auth.user.email, this.origin);
  };

  render() {
    return (
      <div className="Login-bg">
        <div className="Bg-animation">
          <Particles params={ParticlesConfig} width="100%" height="100%" />
        </div>

        <div className="container">
          <div>
            <div className="row">
              <div className="col-md-12 Verify-wrapper-container">
                <div className="Verify-wrapper">
                  <div className="blue-slider">
                    <div className="svg-container">
                      <svg
                        className="svg-curve"
                        viewBox="0 0 200 400"
                        preserveAspectRatio="none"
                      >
                        <path
                          className="s-path"
                          fill="#2D57CD"
                          d="M0,0 100,0 Q-100,200 100,400 L0,400"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="blue">
                    <div>
                      <img src={logo} alt="CIAL-Logo" className="logo" />

                      <div className="Toggle-title text-center text">
                        <div>You are almost there...</div>
                        <div>Verify Email</div>
                      </div>
                    </div>
                  </div>

                  <div className="white">
                    <div className="Form-content">
                      <div className="text-center text Form-title">
                        <div>Welcome to CIAL!</div>
                        <div>
                          Before we get started, please check your email and
                          click the link to verify your account
                        </div>
                        <div>Didn't get the email?</div>
                      </div>
                      {this.props.auth.resendingVerifyError && (
                        <div
                          className="alert-message alert-message-danger"
                          role="alert"
                        >
                          <i className="icon-ic_exclamation-triangle" />
                          {this.props.auth.resendingVerifyError}
                        </div>
                      )}
                      {this.props.auth.resendingVerifySuccess && (
                        <div
                          className="alert-message alert-message-success"
                          role="alert"
                        >
                          <i className="icon-ic_exclamation-triangle" />
                          {this.props.auth.resendingVerifySuccess}
                        </div>
                      )}

                      <button
                        className={
                          "btn Btn-primary Btn-round full-width" +
                          (this.props.auth.resendingVerify ? " disabled" : "")
                        }
                        onClick={this.onSubmit}
                      >
                        Resend Email
                      </button>

                      <FormFooter />

                      <div className="Link-btn">
                        <a href="#" onClick={this.onClick}>
                          Sign up
                        </a>
                        <span> with different email</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

const actionCreators = {
  logout: logout,
  resendVerificationEmail: resendVerificationEmail,
  resendVerifyClear: resendVerifyClear
};

const RegisterPendingConnected = connect(
  mapStateToProps,
  actionCreators
)(RegisterPending);

export default RegisterPendingConnected;
