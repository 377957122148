export const profileConstants = {
    /* Clearing the user's profile. */
    PROFILE_CLEAR: 'PROFILE_CLEAR',

    /* Fetching the user's profile. */
    PROFILE_GET_REQUEST: 'PROFILE_GET_REQUEST',
    PROFILE_GET_SUCCESS: 'PROFILE_GET_SUCCESS',
    PROFILE_GET_FAILURE: 'PROFILE_GET_FAILURE',

    /* Updating the user's profile information. */
    PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
    PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',

    /* Updating the user's avatar. */
    PROFILE_AVATAR_REQUEST: 'PROFILE_AVATAR_REQUEST',
    PROFILE_AVATAR_SUCCESS: 'PROFILE_AVATAR_SUCCESS',
    PROFILE_AVATAR_FAILURE: 'PROFILE_AVATAR_FAILURE',
};
