import React, { Component } from "react";
import { connect } from "react-redux";
import { userValidator } from "../_helpers";
import FormFooter from "../_components/FormFooter";

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      full_name: "",
      email: "",
      password: "",
      submitted: false,
      errors: {
        full_name: "",
        email: "",
        password: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitRegister = props.onSubmit;
  }

  validate = (field, value) => {
    const validateField = userValidator[field] || (() => "");
    const error = validateField(value, false);
    const errors = {
      ...this.state.errors,
      [field]: error,
    };

    this.setState({ errors });

    return !!error;
  };

  validateAll = () => {
    let valid = true;
    for (var field of ["full_name", "email", "password"]) {
      if (this.validate(field, this.state[field])) {
        valid = false;
      }
    }

    return valid;
  };

  handleChange = (event, checked) => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [field]: value,
    });

    this.validate(field, value);
  };

  onSubmit = () => {
    if (!this.validateAll() || this.props.auth.registering) {
      return;
    }

    this.submitRegister(
      this.state.full_name,
      this.state.email,
      this.state.password,
      this.props.auth.language
    );
  };

  onKeyPressHandler = (event) => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  render() {
    return (
      <div className="Form-content">
        <div className="text-center text Form-title">
          <div>Member Sign Up</div>
          {/*TODO NAZIV PROIZVODA CE SE MIJENJATI JEDNOM U BUDUCNOSTI*/}
          <div>to CIAL Dun & Bradstreet C360</div>
        </div>
        {this.props.auth.registerError && (
          <div className="alert-message alert-message-danger" role="alert">
            <i className="icon-ic_exclamation-triangle" />
            {this.props.auth.registerError}
          </div>
        )}
        <div>
          <input
            onKeyDown={this.onKeyPressHandler}
            type="text"
            className="form-control"
            name="full_name"
            placeholder="Full Name"
            onChange={this.handleChange}
          />
          {this.state.errors.full_name && (
            <div className="alert alert-danger" role="alert">
              {this.state.errors.full_name}
            </div>
          )}
          <input
            onKeyDown={this.onKeyPressHandler}
            type="text"
            className="form-control"
            name="email"
            placeholder="E-mail"
            onChange={this.handleChange}
          />
          {this.state.errors.email && (
            <div className="alert alert-danger" role="alert">
              {this.state.errors.email}
            </div>
          )}
          <input
            onKeyDown={this.onKeyPressHandler}
            type="password"
            className="form-control"
            name="password"
            placeholder="Password (7+ characters)"
            onChange={this.handleChange}
          />
          {this.state.errors.password && (
            <div className="alert alert-danger" role="alert">
              {this.state.errors.password}
            </div>
          )}
        </div>
        <button
          className={
            "btn Btn-success Btn-round full-width" +
            (this.props.auth.registering === true ? " disabled" : "")
          }
          onClick={this.onSubmit}
        >
          Sign up
        </button>

        <FormFooter light={true} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const RegisterFormConnected = connect(mapStateToProps, null)(RegisterForm);

export default RegisterFormConnected;
