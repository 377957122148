import { profileConstants } from '../_constants';

const initialState = {
  profile: null,
  updatingProfile: false,
  updateProfileError: '',
  updateProfileSuccess: ''
};

/* Stores a URL to the last user's avatar. */
let lastAvatarUrl = null;

/* The browser caches the avatar so it won't update by default. After each
upload, force the URL to change by supplying a different query param. */
function getAvatarUrl(userId) {
  const baseUrl = `api/v1/users/${userId}/avatar`;
  return baseUrl + `?${new Date().getTime()}`;
}

/* Adds an avatar URL to the profile state. */
function withAvatarUrl(profile, refresh = false) {
  if (refresh || !lastAvatarUrl) {
    lastAvatarUrl = getAvatarUrl(profile.id);
  }
  return {...profile, avatarUrl: lastAvatarUrl};
}

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case profileConstants.PROFILE_CLEAR:
      return {
        ...state,
        updatingProfile: false,
        updateProfileError: '',
        updateProfileSuccess: ''
      };

    case profileConstants.PROFILE_GET_SUCCESS:
      return {
        ...state,
        profile: withAvatarUrl(action.profile),
        updateProfileError: '',
        updateProfileSuccess: '',
      };

    case profileConstants.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        updatingProfile: true,
        updateProfileError: '',
        updateProfileSuccess: '',
      };

    case profileConstants.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profile: withAvatarUrl(action.profile),
        updatingProfile: false,
        updateProfileError: '',
        updateProfileSuccess: 'Profile updated successfully'
      };

    case profileConstants.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        updatingProfile: false,
        updateProfileError: 'Updating profile failed',
        updateProfileSuccess: ''
      };

    case profileConstants.PROFILE_AVATAR_REQUEST:
      return {
        ...state,
        updatingProfile: true,
        updateProfileError: '',
        updateProfileSuccess: '',
      };

    /* Avatar upload success, force refresh the avatar url. */
    case profileConstants.PROFILE_AVATAR_SUCCESS:
      return {
        ...state,
        updatingProfile: false,
        updateProfileSuccess: 'Profile picture updated successfully',
        updateProfileError: '',
        profile: withAvatarUrl(state.profile, true),
      };

    /* Avatar upload failed -- notify the user and keep the last one. */
    case profileConstants.PROFILE_AVATAR_FAILURE:
      return {
        ...state,
        updatingProfile: false,
        updateProfileSuccess: '',
        updateProfileError: 'Updating the profile picture failed',
      };

    default:
      return state;
  }
}
