import { authConstants } from '../_constants';

const initialState = {
  user: null,
  loggingIn: false,
  registering: false,
  verifying: false,
  resendingVerify: false,
  sendingForgotPassword: false,
  changingPassword: false,
  updatingPassword: false,
  loggedIn: false,
  loginError: '',
  logoutError: '',
  registerError: '',
  resendingVerifyError: '',
  resendingVerifySuccess: '',
  verifyEmailError: '',
  forgotPasswordError: '',
  forgotPasswordSuccess: '',
  resetPasswordError: '',
  updatePasswordError: '',
  updatePasswordSuccess: '',
  language: 'en'
}


export function authReducer(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REGISTER_CLEAR:
      return {
        ...state,
        loggingIn: false,
        registering: false,
        loginError: '',
        registerError: ''
      }
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loginError: ''
      }
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        session: action.session,
        token_verify: action.token_verify,
        loggingIn: false,
        loginError: ''
      }
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loginError: 'Login failed',
      }
    case authConstants.LOGIN_AS_REQUEST:
      return {
        ...state
      }
    case authConstants.LOGIN_AS_SUCCESS:
      return {
        ...state,
        user: action.user,
        session: action.session,
        token_verify: action.token_verify,
        loggingIn: false,
        loginError: ''
      }
    case authConstants.LOGIN_AS_FAILURE:
      return {
        ...state
      }
    case authConstants.LOGOUT_REQUEST:
      return {
        ...state,
        logoutError: ''
      }
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        logoutError: ''
      }
    case authConstants.LOGOUT_FAILURE:
      return {
        ...state,
        logoutError: 'Logout failed'
      }
    case authConstants.REGISTER_REQUEST:
      return {
        ...state,
        registering: true,
        registerError: ''
      }
    case authConstants.REGISTER_SUCCESS:
      const user_reg = action.user;

      return {
        ...state,
        user: user_reg,
        session: action.session,
        token_verify: action.token_verify,
        registering: false,
        registerError: '',
      }
    case authConstants.REGISTER_FAILURE:
      return {
        ...state,
        registering: false,
        registerError: 'Sign up failed',
      }
    case authConstants.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifying: true
      }
    case authConstants.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        user: action.user,
        session: action.session,
        token_verify: action.token_verify,
        verifyEmailError: ''
      }
    case authConstants.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyEmailError: 'Verify email failure'
      }
    case authConstants.RESEND_VERIF_EMAIL_CLEAR:
      return {
        ...state,
        resendingVerify: false,
        resendingVerifyError: '',
        resendingVerifySuccess: ''
      }
    case authConstants.RESEND_VERIF_EMAIL_REQUEST:
      return {
        ...state,
        resendingVerify: true,
        resendingVerifyError: '',
        resendingVerifySuccess: ''
      }
    case authConstants.RESEND_VERIF_EMAIL_SUCCEESS:
      return {
        ...state,
        resendingVerify: false,
        resendingVerifyError: '',
        resendingVerifySuccess: 'New verification email has been sent'
      }
    case authConstants.RESEND_VERIF_EMAIL_FAILURE:
      return {
        ...state,
        resendingVerify: false,
        resendingVerifyError: 'Sending verification email failed',
        resendingVerifySuccess: ''
      }
    case authConstants.FORGOT_PASSWORD_CLEAR:
      return {
        ...state,
        sendingForgotPassword: false,
        forgotPasswordError: '',
        forgotPasswordSuccess: ''
      }
    case authConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        sendingForgotPassword: true,
        forgotPasswordError: '',
        forgotPasswordSuccess: ''
      }
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        sendingForgotPassword: false,
        forgotPasswordError: '',
        forgotPasswordSuccess: "If you have an account, we'll email you a reset link"
      }
      // TODO ZA FORGOT PASS FAILURE DODAT ALERT FAILED MESSAGE SAMO U SLUCAJU DA JE NESTO SA SERVEROM A NE AKO UNESE KRIVI EMAIL S KOJIM SE NEMA ACCOUNT

    case authConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        sendingForgotPassword: false,
        forgotPasswordError: 'Error occured while processing request',
        forgotPasswordSuccess: '',
      }
    case authConstants.RESET_PASSWORD_CLEAR:
      return {
        ...state,
        changingPassword: false,
        resetPasswordError: ''
      }
    case authConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        changingPassword: true,
        resetPasswordError: ''
      }
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.user,
        session: action.session,
        token_verify: action.token_verify,
        changingPassword: false,
        resetPasswordError: ''
      }
    case authConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        changingPassword: false,
        resetPasswordError: 'Error occured while processing request'
      }
    case authConstants.UPDATE_PASSWORD_CLEAR:
      return {
        ...state,
        updatingPassword: false,
        updatePasswordError: '',
        updatePasswordSuccess: ''
      }
    case authConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        updatingPassword: true,
        updatePasswordError: '',
        updatePasswordSuccess: ''
      }
    case authConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatingPassword: false,
        updatePasswordError: '',
        updatePasswordSuccess: 'Password updated successfully'
      }
    case authConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatingPassword: false,
        updatePasswordError: 'Error in updating password',
        updatePasswordSuccess: ''
      }
    case authConstants.SESSION_REFRESH_SUCCESS:
      return {
        ...state,
        session: action.session,
        token_verify: action.token_verify
      }
    case authConstants.SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      }
    default:
      return state
  }
}
