const PROFILE_ROUTE = '/api/v1/users';

function getProfile() {
  return fetch(PROFILE_ROUTE + '/me').then(handle);
}

function updateProfile(userId, updateData) {
  const opts = {
    method: 'PATCH',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify(updateData),
  };

  return fetch(PROFILE_ROUTE + '/' + userId, opts).then(handle);
}

function updateAvatar(userId, file) {
  const data = new FormData();
  data.append('image', file);
  const opts = {method: 'PUT', body: data};
  return fetch('api/v1/users/avatar', opts).then(handle);
}

function handle(response) {
  if (response.ok) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if(data && data.language){
        window.Localize.setLanguage(data.language);
        if (window.drift && window.drift.config) {
          window.drift.config({
            locale: data.language
          });
        }
      }
      
      return data
    });
  } else {
    return Promise.reject(response);
  }
}

export const profileService = {
  getProfile,
  updateProfile,
  updateAvatar,
};
