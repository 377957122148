import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Localize } from "react-common/services";
import { LANGUAGES } from "../_helpers/language";
import { setLanguage } from "../_actions";
import "./FormFooter.css";

const FormFooter = (props) => {
  const { language, setLanguage, light = false } = props;
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);

  useEffect(() => {
    setLanguage(Localize.getLanguage());
  }, [setLanguage]);

  const changeLanguage = (lang) => {
    Localize.setLanguage(lang);
    setLanguage(lang);
    setIsLangDropdownOpen(false);
  };

  const renderLangItems = () =>
    Object.keys(LANGUAGES).map((v, k) =>
      v !== language ? (
        <div key={k}>
          <span
            onClick={() => {
              changeLanguage(v);
            }}
          >
            {LANGUAGES[v]}
          </span>
        </div>
      ) : null
    );
  return (
    <div className={`FormFooter ${light ? "FormFooter--light" : ""}`}>
      <div
        className={`FormFooter-dropdown ${
          isLangDropdownOpen ? "FormFooter-dropdown--open" : ""
        }`}
      >
        <span
          onClick={() => {
            setIsLangDropdownOpen(!isLangDropdownOpen);
          }}
        >
          {_.get(LANGUAGES, language, "")}
        </span>{" "}
        <i className="icon-ic_arrow"></i>
        <div className="FormFooter-dropdown-items">
          {isLangDropdownOpen && renderLangItems()}
        </div>
      </div>

      <div>
        <a
          className="mr-3"
          href="https://www.cialdnb.com/en/privacy-policy/"
          target="_blank"
        >
          <span>Privacy</span>
        </a>
        <a href="https://www.cialdnb.com/en/terms-of-use/" target="_blank">
          <span>Terms</span>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.auth.language,
  };
};

const actionCreators = {
  setLanguage: setLanguage,
};

const FormFooterConnected = connect(
  mapStateToProps,
  actionCreators
)(FormFooter);

export default FormFooterConnected;
