import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import logo from '../../_images/logo-circle.svg';
import { Link } from 'react-router-dom';
import './Sidebar.css';

class Sidebar extends Component {
    constructor(props) {
      super(props);

      function getSelected(path){
        if (path === '/'){
          return 'personal'
        }

        if (path === '/security'){
          return 'security'
        }
      }

      this.state = {
        selected: getSelected(this.props.pathname),
      };
    }

    render() {
        return (
            <div className="Sidebar">

                <div className="Sidebar-menu d-flex flex-column align-items-center">

                    <img src={logo} alt="logo"/>

                    <div className={"Link d-flex flex-column align-items-center " +
                    (this.state.selected === "personal" ? "active" : "")}>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={<Tooltip>Personal info</Tooltip>}>
                            <Link to="/">
                                <i className="icon-ic_personal-info"/>
                            </Link>
                        </OverlayTrigger>

                      {this.state.selected === 'personal' && (
                        <div className="Indicator-arrow"></div>
                      )}

                    </div>

                    <div className={"Link d-flex flex-column align-items-center " +
                    (this.state.selected === "security" ? "active" : "")}>
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip>
                                    Security
                                </Tooltip>
                            }
                        >
                            <Link to="/security">
                                <i className="icon-ic_security"/>
                            </Link>
                        </OverlayTrigger>

                      {this.state.selected === 'security' && (
                        <div className="Indicator-arrow"></div>
                      )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
  return { pathname: state.router.location.pathname }
}

export default connect(
  mapStateToProps,
  {},
)(Sidebar)
