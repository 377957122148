import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import btnArrow from "../_images/btn-arrow.svg";
import "./LoginRegisterMobile.css";

const LoginRegisterMobile = ({
  location,
  isRegisterOpen,
  onSwitchHandler,
  onSubmitRegister,
  onSubmitLogin
}) => {
  return (
    <Container>
      <Row>
        <Col>
          <div className={`LoginRegisterMobile ${isRegisterOpen ? "switch" : ""}`}>
            <div className="Btn-arrow" onClick={onSwitchHandler}>
              <img className="Btn-arrow-img" src={btnArrow} alt="Toggle between login and register"/>
            </div>
            <div className="LoginRegisterMobile-inner">
              <div className="Login">
                <LoginForm location={location} onSubmit={onSubmitLogin} />
                <div className="Toggle-title text-center text" onClick={onSwitchHandler}>
                  <div>Don't have an account?</div>
                  <div>Sign up</div>
                </div>
              </div>
              <div className="Register">
                <RegisterForm location={location} onSubmit={onSubmitRegister} />
                <div className="Toggle-title text-center text" onClick={onSwitchHandler}>
                  <div>Already have an account?</div>
                  <div>Log in</div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginRegisterMobile;
