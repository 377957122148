import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { refreshSession } from '../_actions';
import * as qs from 'query-string'

class PrivateRoute extends Component {
  constructor(props){
    super(props);

    this.state = {
      userRefreshed: false
    };
  }

  componentDidMount(){
    const self = this;
    this.props.refreshSession()
      .then(function(r){
        self.setState({ userRefreshed: true })
      })
  }

  render() {

    if(!this.state.userRefreshed){
      return null;
    }

    const pendingPath = '/pending'

    const parsedQs = qs.parse(this.props.location.search)
    var origin = parsedQs.origin

    const from = this.props.location

    const { user, session, token_verify } = this.props

    // TODO add exiry somehow
    const isExpired = false
    //const isExpired = user && user.exp < current
    
    function createPath(path, origin){
      if (origin){
        return path + '?origin=' + origin;
      }

      return path;
    }

    // if user is logged in but not verified redirect to pending
    if (user && !user.verified && from.pathname !== pendingPath) {
      const path = createPath('/pending', origin);
      return <Redirect to={path} />;
    }

    // if user is logged in and verified and tries to go to pending
    // page, redirect him to homepage
    if (user && user.verified && from.pathname === pendingPath) {
      const path = createPath('/', origin);
      return <Redirect to={path} />;
    }

    if (!user || isExpired) {
      // if user is logging out -> redirect to
      // login, but do not save location because
      // we always want to open home page
      // TODO see what to do with this
      /*
      if (this.props.loggingOut) {
        return <Redirect to='/login' />
      }
      */

      // if user paste a url in address bar but was
      // logged out, it will be redirected to login,
      // but in this case after login we want to redirect
      // to that url
      const path = createPath('/login', origin);
      return <Redirect to={path} />;
    }

    // if user is logged in and verified and there is origin
    if (user && user.verified && origin && window.location.pathname !== '/loginas') {
      origin += `?session=${session.id}`;
      origin += `&token=${token_verify}`;
      setTimeout(function(){
        window.location.href = origin;
        return null;
      }, 200)
      return null;
      //window.location.assign(origin);
    }

    return <Route {...this.props}>{this.props.children}</Route>
  }
}

const mapStateToProps = (state) => {
  return {
    loggingOut: state.auth.loggingOut,
    user: state.auth.user,
    session: state.auth.session,
    token_verify: state.auth.token_verify,
  }
}

const actionCreators = {
    refreshSession: refreshSession,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(PrivateRoute)
