
export const profileValidator = {
  'name': function(value, edit = false){
    if (!value){
      return 'Full name must be provided';
    }

    return null;
  },
  'phone': function(value, edit = false){
    return null
  }
}
